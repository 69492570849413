<template>
  <v-card elevation="0" class="pa-0 mt-3 mb-4 item-card flex-grow-1" :class="cardClass" @click="clickCard()">
    <v-container class="pa-0">
      <v-row no-gutters>
        <template v-for="(dataItem, index) in data">
          <v-col :key="dataItem.id" cols="4" v-if="dataItem.text">
            <div class="py-2 pl-3">{{ dataItem.text }}：</div>
          </v-col>
          <v-col 
            :key="dataItem.id"
            :cols="
              (index === data.length - 1 ? 6 : 
              (dataItem.valueCol || 8)) + (dataItem.text ? 0 : 4)
            ">
            <div :class="[dataItem.class, !dataItem.text ? 'py-2 pl-3' : 'py-2 pr-2']">
              {{ dataItem.value }}
            </div>
          </v-col>
        </template>

        <v-col cols="2">
          <div class="py-2 text-center item-card-btn pointer">
            {{ actionText }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default: "primary",
      validator(value) {
        return ["primary", "secondary", 'lighten', 'dark', 'error'].includes(value);
      },
    },
    data: {
      type: Array,
      default: () => [{ text: "label", value: "value" }],
    },
    actionText: {
      type: String,
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('member', ['currMemberRole']),
    cardClass() {
      if (this.type === "primary") return "item-card--primary";
      if (this.type === "secondary") return "item-card--secondary";
      if (this.type === "lighten") return "item-card--lighten";
      if (this.type === "dark") return "item-card--dark";
      if (this.type === "error") return "item-card--error";
      return "";
    },
  },
  methods: {
    clickCard() {
      if (this.currMemberRole._isManager) {
        this.action()
      } 
    },
  }
};
</script>

<style lang="sass">
.linerp-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #009775
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #009775
      border: 1px solid #009775
.provider-store-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #006DE0
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #006DE0
      border: 1px solid #006DE0

.item-card--lighten
  .item-card-btn
      background: #ffffff
      color: #00E086
      border: 1px solid #00E086
.item-card--dark
  .item-card-btn
      background: #ffffff
      color: #122A47
      border: 1px solid #122A47
.item-card--error
  .item-card-btn
      background: #ffffff
      color: #E00000
      border: 1px solid #E00000

</style>